const SweetAlertMixin = {
  methods: {
    confirm(callback, options) {
      const data = {
        title: this.$t('notifications.warning'),
        text: this.$t('notifications.confirm.destroy.text'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('notifications.confirm.destroy.buttons.cancel'),
        confirmButtonText: this.$t('notifications.confirm.destroy.buttons.yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        ...options || {},
      }

      this.$swal(data).then(result => {
        if (result.isConfirmed) {
          callback(result)
        }
      })
    },
  },
}

export default SweetAlertMixin
